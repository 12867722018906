/** The names of the baggage that will be logged by the Meow logger
 * (Baggage is values stored in a trace context) */
export enum MeowTags {
  /** The user ID. Will be populated automatically from the ACCESS_TOKEN, if available. */
  SUB = "sub",
  /** The user email. Will be populated automatically from the ACCESS_TOKEN, if available. */
  EMAIL = "email",
  /** The datadog frontend session ID. Will be populated through the DD-Session header or _dd_s cookie if available. */
  DD_SESSION = "session_id",
  /** Automatically generated for each incoming request.
   * Useful if you want to follow the logs for a specific request. */
  DD_REQ_ID = "ddRequestId",
  /** The impersonated user ID. Will be populated automatically from the ACCESS_TOKEN, if available. */
  ISUB = "isub",
  ANON_USER_ID = "anonUserId",
  /** The legacy customer ID. Will be populated automatically from the ACCESS_TOKEN, if available.  */
  OLD = "oldSub",
  /** The legacy impersonated customer ID. Will be populated automatically from the ACCESS_TOKEN, if available.  */
  IOLD = "iOldSub",
}
